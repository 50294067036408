html,
body {
  padding: 0;
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

[role="button"] {
  cursor: pointer;
}

.loading-dots {
  width: 17px;
  display: flex;
  flex-direction: row;
}

.loading-dots:before {
  animation: dots 2s linear infinite;
  content: "";
}

@keyframes dots {
  0%,
  20% {
    content: ".";
  }
  40% {
    content: "..";
  }
  60% {
    content: "...";
  }
  90%,
  100% {
    content: "";
  }
}
